<template>
  <div class="course_main">
    <div class="course_title_box">
      <h5>相关课程</h5>
    </div>
    <div class="course_list">
      <a-carousel arrows :dots="false">
        <div
          slot="prevArrow"
          class="custom-slick-arrow"
          style="left: -50px; top: calc(50% - 10px)"
          v-if="courses.length > 1"
        >
          <a-icon type="left-circle" />
        </div>
        <div
          slot="nextArrow"
          class="custom-slick-arrow"
          v-if="courses.length > 1"
          style="right: 0; top: calc(50% - 10px)"
        >
          <a-icon type="right-circle" />
        </div>
        <div
          class="car_cont"
          v-for="(c_item, c_index) in courses"
          :key="c_index"
        >
          <a-row :gutter="30">
            <a-col
              class="gutter-row"
              :span="8"
              v-for="(list, index) in c_item"
              :key="index"
            >
              <div class="card_box" @click="goCourse(list.id)">
                <img :src="QiNiuPath + list.courseLogo" alt="" />
                <p>{{ list.name }}</p>
              </div>
            </a-col>
          </a-row>
        </div>
      </a-carousel>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "work_course",
  data() {
    return {};
  },
  props: ["details"],
  computed: {
    ...mapState({
      QiNiuPath: (state) => state.indexStore.QiNiuPath,
    }),
    courses() {
      let arr = [];
      let c_list = this.details.courseList || [];
      if (c_list.length > 0) {
        //每次循环三个
        for (let i = 0; i < c_list.length; i += 3) {
          //sclice||splice：会返回一个新的数组push到arr中、双层arr
          arr.push(c_list.slice(i, i + 3));
        }
      }
      return arr;
    },
  },
  methods: {
    goCourse(id) {
      this.$router.push({ path: "/VOfflineClassesDetail", query: { id: id } });
    },
  },
};
</script>
<style lang="scss" scoped>
.ant-carousel {
  ::v-deep .slick-slider {
    overflow: hidden;
  }
  .custom-slick-arrow:before {
    display: none;
  }
  .custom-slick-arrow {
    opacity: 0;
    color: #33485e;
    font-size: 40px;
    transition: all 0.2s;
  }
}
.ant-carousel:hover {
  .slick-prev {
    opacity: 0.7;
    left: 18px !important;
    transition: all 0.2s;
    z-index: 999;
  }
  .slick-next {
    opacity: 0.7;
    right: 38px !important;
    transition: all 0.2s;
  }
}

.course_main {
  width: 1200px;
  margin: 37px auto 0 auto;
  .course_title_box {
    h5 {
      font-size: 28px;
      font-weight: 500;
      color: #333a48;
      line-height: 40px;
      padding-left: 17px;
      position: relative;
      &::before {
        position: absolute;
        top: calc(50% - 12px);
        left: 0;
        width: 7px;
        height: 26px;
        background: rgba(116, 101, 255, 1);
        border-radius: 5px;
        content: "";
      }
    }
  }
  .course_list {
    margin-left: -15px;
    margin-right: -15px;
    padding-bottom: 39px;
    margin-top: 27px;
    ::v-deep .slick-list {
      padding-bottom: 10px;
    }
    ::v-deep .ant-row {
      margin: 0 !important;
    }
    .car_cont {
      .card_box {
        width: 100%;
        padding: 20px 20px 25px 20px;
        background: #fff;
        box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        box-sizing: border-box;
        cursor: pointer;
        img {
          width: 100%;
          height: 200px;
        }
        p {
          font-size: 16px;
          font-weight: 800;
          color: #33485e;
          line-height: 22px;
          margin-top: 12px;
          text-align: center;
        }
      }
    }
  }
}
</style>