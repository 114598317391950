import { render, staticRenderFns } from "./workCourse.vue?vue&type=template&id=311edd3e&scoped=true&"
import script from "./workCourse.vue?vue&type=script&lang=js&"
export * from "./workCourse.vue?vue&type=script&lang=js&"
import style0 from "./workCourse.vue?vue&type=style&index=0&id=311edd3e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "311edd3e",
  null
  
)

export default component.exports